<template>
  <div class="text-row" :class="{border:showBorder}">
    <div class="text-row__label">{{label}}</div>
    <div class="text-row__content" :class="{edit:isEdit}">
      <slot></slot>
    </div>
    <!-- <div class="text-row__edit">
      <Icon type="md-create" v-if="isEdit" @click="onEdit" />
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onEdit () {
      this.$emit('edit')
    }
  }
}
</script>

<style lang="scss" scoped>
.text-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 14px 0;
    &.border {
        border-bottom: 1px solid #e1e1e1;
    }
    &__label {
        width: 120px;
        margin-right: 20px;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #999999;
    }
    &__content {
        flex: 1;
        color: #999999;
        &.edit {
            color: #333333 !important;
        }
    }

    &__edit {
        width: 100px;
        color: #67c5ec;
        font-size: 16px;
        .ivu-icon {
            cursor: pointer;
        }
    }
}
</style>
