<template>
  <div>
    <div class="ivu-result-icon ivu-result-icon-success">
      <Icon type="ios-checkmark-circle" />
    </div>
    <div class="ivu-result-title">新密码重置已完成，请重新登录 </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.ivu-result-icon-success {
  background-color: rgba(82, 196, 26, 0.15);
}
.ivu-result-icon {
  width: 84px;
  height: 84px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ivu-result-icon .ivu-icon {
  color: #52c41a;
  font-size: 50px;
}
.ivu-result-title {
  margin-bottom: 16px;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
</style>
