<template>
  <card-box title="企业认证">
    <div v-if="get(usrAuthInfo,'status') !== 0" style="width:400px;margin:0 auto;padding-top:30px;">
      <!-- 未认证 -->
      <div v-if="!get(usrAuthInfo,'id')" class="text-center">
        <p style="margin:10px 0 20px 0;">
          <Icon type="ios-alert" style="color:#f90;font-size:16px;" /> 您还没进行企业认证，请立即进行企业认证
        </p>
        <Button type="primary" @click="showAuthMoal">立即认证</Button>
      </div>
      <!-- 审核中 -->
      <div v-if="get(usrAuthInfo,'status') === 1" class="text-center">
        <div class="">
          <Icon type="ios-time" style="font-size:50px;color:#67C5EC" />
        </div>
        <p>您的申请已提交，请耐心等待审核 </p>
      </div>
      <!-- 审核失败 -->
      <div v-if="get(usrAuthInfo,'status') === 2" class="text-center">
        <div>
          <Icon type="ios-close-circle" style="font-size:50px;color:#ed4014;" />
        </div>
        <p style="margin:10px 0 20px 0;">很抱歉，您的审核没有通过请重新进行认证申请 </p>
        <Button type="primary" @click="showAuthMoal">立即认证</Button>
      </div>
    </div>
    <!-- 认证成功 -->
    <div v-else>
      <Row>
        <Col :span="24">
        <TextRow label="公司名称">
          {{get(usrAuthInfo,'companyName') || '-'}}
        </TextRow>
        </Col>
        <Col :span="24">
        <TextRow label="统一社会信用代码">
          {{get(usrAuthInfo,'registrationNo') || '-'}}
        </TextRow>
        </Col>
        <Col :span="24">
        <TextRow label="认证状态">
          <Icon type="ios-checkmark-circle" color="#19be6b" /> 成功
        </TextRow>
        </Col>
      </Row>
    </div>
    <div v-if="showAuth">
      <AuthModal ref="AuthModal" @cancel="closeModal" @success="getAuthInfo"></AuthModal>
    </div>

  </card-box>
</template>

<script>
import AuthModal from './AuthModal'
import TextRow from '@/components/TextRow'
import { getAuthInfo } from '@/api/packing'
import { mapGetters } from 'vuex'
export default {
  components: {
    AuthModal,
    TextRow
  },
  data () {
    return {
      showAuth: false,
      usrAuthInfo: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'token'])
  },
  created () {
    this.getAuthInfo()
  },
  methods: {
    getAuthInfo () {
      getAuthInfo(this.userInfo.userId).then(res => {
        if (res.code === 0) {
          this.usrAuthInfo = res.data || {}
        }
      })
    },
    showAuthMoal () {
      this.showAuth = true
      this.$nextTick(() => {
        this.$refs.AuthModal.show(this.usrAuthInfo)
      })
    },
    closeModal () {
      this.showAuth = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
