<template>
  <Modal v-model="visible" footer-hide @on-cancel="cancel" width="440px" class-name="passowrd-dialog" :mask-closable="false">
    <div slot="header" class=""> {{title}}</div>
    <Steps :current="current" class="my-steps" size="small">
      <Step title="输入原密码 ">
      </Step>
      <Step title="输入新密码"></Step>
      <Step title="完成"></Step>
    </Steps>
    <div style="padding:20px 0 0;">
      <ValidatePassword v-show="current === 0" ref="Step0" @success="validatePassword"></ValidatePassword>
      <UpdatePassword v-show="current === 1" ref="Step1" :oldpass="oldpass"></UpdatePassword>
      <Success v-show="current === 2" ref="Step2"></Success>
      <div v-if="current < 2"><Button type="primary" long @click="next">下一步</Button></div>
      <div v-else><Button type="primary" long @click="ok()">重新登陆</Button></div>
    </div>
  </Modal>
</template>

<script>
import ValidatePassword from './Step1'
import UpdatePassword from './Step2'
import Success from './Step3'
export default {
  components: {
    ValidatePassword,
    UpdatePassword,
    Success
  },
  data () {
    return {
      visible: false,
      current: 0,
      oldpass: {}
    }
  },
  computed: {
    title () {
      let title = '为确保是您本人操作，请完成密码验证'
      switch (title) {
        case 0:
          title = '为确保是您本人操作，请完成密码验证'
          break
        case 1:
          title = '请输入新密码完成修改'
          break
        case 2:
          title = '重置完成'
          break

        default:
          break
      }
      return title
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    ok () {
      this.$store.dispatch('LOGOUT').then(res => {
        this.$router.push('/')
      })
    },
    cancel () {
      this.$emit('cancel')
    },
    next () {
      this.$refs['Step' + this.current].validate().then(res => {
        this.current++
      })
    },
    validatePassword (data) {
      this.oldpass = data
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
