<template>
  <div>
    <Form :model="form" ref="Form" :rules="rules">
      <FormItem prop="passwd">
        <Input type="password" v-model="form.newPwd" placeholder="请输入新密码"></Input>
      </FormItem>
      <FormItem prop="passwdCheck">
        <Input type="password" v-model="form.newPwdCheck" placeholder="请确认原密码"></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { resetPwdByOldPwd } from '@/api/user'
export default {
  props: {
    oldpass: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.form.newPwd) {
        callback(new Error('与新密码不匹配'))
      } else {
        callback()
      }
    }
    return {
      form: {
        newPwd: '',
        newPwdCheck: '',
        oldPwd: ''
      },
      rules: {
        newPwd: [{ required: true, message: '请输入6-12位长度的新密码' }],
        newPwdCheck: [{ validator: validatePassCheck, trigger: 'blur' }]
      }
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.Form.validate((valid) => {
          if (valid) {
            this.form.oldPwd = this.oldpass.oldPwd
            resetPwdByOldPwd(this.form).then(res => {
              if (res.isSuccess) {
                return resolve()
              } else {
                this.$Message.error(res.msg)
              }
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
