<template>
  <div>
    <Form :model="form" ref="Form" :rules="rules">
      <FormItem prop="passwd">
        <Input type="password" v-model="form.oldPwd" placeholder="请输入原密码"></Input>
      </FormItem>
      <FormItem prop="passwdCheck">
        <Input type="password" v-model="form.oldPwdCheck" placeholder="请确认原密码"></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { validataPwd } from '@/api/user'
export default {
  data () {
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请确认原密码'))
      } else if (value !== this.form.oldPwd) {
        callback(new Error('与原密码不匹配'))
      } else {
        callback()
      }
    }
    return {
      form: {
        oldPwd: '',
        oldPwdCheck: ''
      },
      rules: {
        oldPwd: [{ required: true, message: '请输入原密码' }],
        oldPwdCheck: [{ validator: validatePassCheck, trigger: 'blur' }]
      }
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.Form.validate((valid) => {
          if (valid) {
            this.$emit('success', this.form)
            validataPwd(this.form).then(res => {
              if (res.isSuccess) {
                return resolve()
              } else {
                this.$Message.error(res.msg)
              }
            })
          } else {
            return reject(valid)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
