<template>
  <page-view title="个人中心">
    <card-box title="基本信息">
      <template slot="extral">
        <Button ghost type="primary" size="small" @click="$refs.EditBaseInfo.show(userInfo)">编辑</Button>
      </template>
      <Row>
        <Col :span="12">
        <TextRow label="账号昵称">
          {{userInfo.nickName || '-'}}
        </TextRow>
        </Col>
        <Col :span="12">
        <TextRow label="姓名/性别">
          {{userInfo.userName}}/{{userInfo.sex === '0' ? '男' :'女'}}
        </TextRow>
        </Col>
        <Col :span="12">
        <TextRow label="绑定手机">
          {{userInfo.phonenumber|formatTel}}
        </TextRow>
        </Col>
        <Col :span="12">
        <TextRow label="所属行业">
          {{userInfo.businessScopeName || '-'}} / {{userInfo.industry || '-'}}
        </TextRow>
        </Col>
        <Col :span="12">
        <TextRow label="注册时间" :showBorder="false" :isEdit="false">
          {{userInfo.createTime}}
        </TextRow>
        </Col>
        <Col :span="12">
        <TextRow label="公司名称" :showBorder="false">
          {{userInfo.company || '-'}}
        </TextRow>
        </Col>
      </Row>
    </card-box>
    <div style="margin-top: 40px;">
      <card-box title="密码设置">
        <Row>
          <Col :span="12">
          <TextRow label="账号密码" :isEdit="false" :showBorder="false">
            <Button type="primary" ghost @click="$refs.ResetPassword.show()">修改密码</Button>
            <Button type="primary" ghost style="margin-left: 30px;" @click="$refs.ForgetPassword.show()">找回密码</Button>
          </TextRow>
          </Col>
        </Row>
        <Row>
          <Col :span="12">
          <TextRow label="绑定手机" :isEdit="false" :showBorder="false">
            {{userInfo.phonenumber|formatTel}}
            <Button type="primary" ghost style="margin-left: 30px;" @click="$refs.ResetMobile.show()">修改</Button>
          </TextRow>
          </Col>
        </Row>
      </card-box>
    </div>
    <div style="margin-top: 40px;">
      <EnterpriseCertification></EnterpriseCertification>
    </div>
    <EditBaseInfo ref="EditBaseInfo"></EditBaseInfo>
    <ResetPassword ref="ResetPassword"></ResetPassword>
    <ForgetPassword ref="ForgetPassword"></ForgetPassword>
    <ResetMobile ref="ResetMobile"></ResetMobile>
  </page-view>
</template>

<script>
import TextRow from '@/components/TextRow'
import ResetPassword from './components/ResetPassword/index'
import ForgetPassword from './components/ForgetPassword/index'
import ResetMobile from './components/ResetMobile/index'
import EditBaseInfo from './components/EditBaseInfo/index'
import EnterpriseCertification from './components/EnterpriseCertification/index'
import { mapGetters } from 'vuex'
export default {
  components: {
    TextRow,
    ResetPassword,
    ForgetPassword,
    EditBaseInfo,
    ResetMobile,
    EnterpriseCertification
  },
  data () {
    return {
      showRestPasswordDialog: false
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
</style>
