<template>
  <Modal v-model="visible" title="企业身份认证" width="600px" :mask-closable="false">
    <Alert type="warning">请按照要求填写认证信息，认证成功后将为您开启企业版权限</Alert>
    <Form :model="form" ref="Form" :rules="rules" :label-width="120" label-position="left">
      <FormItem prop="companyName" label="公司名称">
        <Input v-model="form.companyName" placeholder="请按照营业执照上的名字核对输入"></Input>
      </FormItem>
      <FormItem prop="registrationNo" label="">
        <Input v-model="form.registrationNo" placeholder="请填写营业执照上的注册号"></Input>
      </FormItem>
      <FormItem prop="area" label="企业注册地区">
        <Select v-model="form.province" placeholder="请选省" filterable clearable
                style="display: inline-block;width:32%;margin-right:1%;" @on-change="form.city=''">
          <Option v-for="(item,index) in province_list" :key="index" :value="item.name">{{item.name}}</Option>
        </Select>
        <Select v-model="form.city" placeholder="请选市" filterable clearable
                style="display: inline-block;width:32%;margin-right:1%;" @on-change="form.area=''">
          <Option v-for="(item,index) in city_list" :key="index" :value="item.name">{{item.name}}</Option>
        </Select>
        <Select v-model="form.area" placeholder="请选区" filterable clearable style="display: inline-block;width:34%;">
          <Option v-for="(item,index) in county_list" :key="index" :value="item.name">{{item.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="address" label="">
        <Input v-model="form.address" placeholder="请输入详细地址"></Input>
      </FormItem>
      <FormItem prop="file" label="上传营业执照">

        <Upload ref="upload" :show-upload-list="false" :on-success="handleSuccess" :format="['jpg','jpeg','png']"
                :max-size="1024*8" :on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize"
                :before-upload="handleBeforeUpload" :multiple="false" type="drag" accept=".jpg,.png,.jpeg,.gif"
                :headers="headers" action="dist/userAuth/add" style="display: inline-block;width:120px;">
          <div style="width: 120px;height:120px;line-height: 120px;">
            <img v-if="imgSrc" :src="imgSrc" alt="" style="width:120px;height:120px;">
            <Icon v-else type="ios-image" size="30" />
          </div>
        </Upload>
        <p>不超过8M，支持格式：“jpg” “jpeg” “png”</p>
      </FormItem>
    </Form>
    <span slot="footer">
      <Button ghost type="primary" @click="visible=false">取消</Button>
      <Button type="primary" style="margin-left:20px;" @click="ok()">确定</Button>
    </span>
  </Modal>
</template>

<script>
// import { userAuth } from '@/api/packing'
import { mapGetters } from 'vuex'
import axios from 'axios'
const areaList = window.areaList

const getColumnValues = (type, code) => {
  let column = []
  if (type !== 'province' && !code) {
    return column
  }

  const list = areaList[type]
  column = Object.keys(list).map((listCode) => ({
    code: listCode,
    name: list[listCode]
  }))

  if (code) {
    column = column.filter((item) => item.code.startsWith(code))
  }

  return column
}

export default {
  data () {
    const checkFile = (rule, value, callback) => {
      if (this.imgSrc === '') {
        callback(new Error('请上传营业执照'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      form: {
        province: '',
        city: '',
        area: '',
        file: null
      },
      imgSrc: '',
      rules: {
        companyName: [{ required: true, message: '请输入公司名称' }],
        registrationNo: [{ required: true, message: '请输入营业执照注册号' }],
        area: [{ required: true, message: '请选择所在省市区' }],
        address: [{ required: true, message: '请输入详细地址' }],
        file: [{ validator: checkFile, message: '请上传营业执照' }]

      }
    }
  },
  watch: {
    visible: function (newval) {
      if (!newval) this.cancel()
    },
    'form.province': function (newval) {
      // this.form.city = ''
    },
    'form.city': function (newval) {
      // this.form.county = ''
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'token']),
    headers () {
      return {
        token: this.token
      }
    },
    formData () {
      const formData = new FormData()
      for (const key in this.form) {
        formData.append(key, this.form[key])
      }
      return formData
    },
    province_list () {
      return Object.keys(areaList.province_list).map((listCode) => ({
        code: listCode,
        name: areaList.province_list[listCode]
      }))
    },
    city_list () {
      if (this.form.province) {
        const province = this.province_list.find(e => e.name === this.form.province)
        return getColumnValues('city_list', province.code.slice(0, 2))
      } else {
        return []
      }
    },
    county_list () {
      if (this.form.city) {
        const city = this.city_list.find(e => e.name === this.form.city)
        return getColumnValues('county_list', city.code.slice(0, 4))
      } else {
        return []
      }
    }
  },
  methods: {
    show (data = {}) {
      if (data) {
        const { imageUrl, id, companyName, province, city, area, address, registrationNo } = data
        this.form = {
          id,
          companyName,
          province,
          city,
          area,
          address,
          registrationNo
        }
        this.imgSrc = imageUrl
      }
      this.visible = true
    },
    ok () {
      this.$refs.Form.validate(valid => {
        if (valid) {
          axios.post('/dist/userAuth/add', this.formData, {
            headers: {
              token: this.token,
              'Content-Type': 'multipart/form-data,boundary=' + new Date().getTime()
            }
          }).then(res => {
            if (res.data.code === 0) {
              this.$Message.success('提交成功')
              this.visible = false
              this.$emit('success')
            } else {
              this.$Message.error(res.data.msg)
            }
          })
          // userAuth(this.formData).then(res => {

          // })
        }
      })
    },
    cancel () {
      setTimeout(() => {
        this.$emit('cancel')
      }, 500)
    },
    handleSuccess (res, file) {

    },
    handleFormatError (file) {
      this.$Message.warning('只能上传jpg,png格式的图片')
    },
    handleMaxSize (file) {
      this.$Message.warning('最大只支持8M')
    },
    handleBeforeUpload (file) {
      this.$set(this.form, 'file', file)
      this.$refs.Form.validateField('file')
      this.imgSrc = window.URL.createObjectURL(file)
      console.log(file)
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
