<template>
  <div>
    <Form :model="form" ref="Form" :rules="rules">
      <FormItem prop="phonenumber">
        <Input v-model="form.phonenumber" placeholder="请输入新手机号码"></Input>
      </FormItem>
      <FormItem prop="code">
        <Input v-model="form.code" placeholder="请输入验证码"
               style="display: inline-block;margin-right:20px;width:calc(100% - 150px)"></Input>
        <CountDown v-model="form.recordId" :tel="form.phonenumber" :templateType="2"></CountDown>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import CountDown from '@/components/CountDown'
import { updateMobile } from '@/api/user'
export default {
  components: {
    CountDown
  },
  data () {
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.form.passwd) {
        callback(new Error('与新密码不匹配'))
      } else {
        callback()
      }
    }
    return {
      form: {
        phonenumber: '',
        code: ''
      },
      rules: {
        passwd: [{ required: true, message: '请输入6-12位长度的新密码' }],
        passwdCheck: [{ validator: validatePassCheck, trigger: 'blur' }]
      }
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.Form.validate((valid) => {
          if (valid) {
            updateMobile(this.form).then(res => {
              if (res.isSuccess) {
                return resolve()
              } else {
                this.$Message.error(res.msg)
              }
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
