<template>
  <Modal v-model="visible" @on-cancel="cancel" :mask-closable="false">
    <div slot="header" class=""> 基本信息</div>
    <div style="padding: 0 20px;">
      <Form :model="form" ref="Form" :rules="rules" :label-width="100" label-position="left">
        <FormItem label="账号昵称" prop="nickName">
          <Input v-model="form.nickName" placeholder="请输入账号昵称"></Input>
        </FormItem>
        <FormItem label="姓名" prop="userName">
          <Input v-model="form.userName" placeholder="请输入姓名"></Input>
        </FormItem>
        <FormItem label="性别" prop="sex">
          <Select v-model="form.sex">
            <Option value="0">男</Option>
            <Option value="1">女</Option>
          </Select>
        </FormItem>
        <FormItem label="所属行业" prop="businessScope">
          <Select v-model="form.businessScope" placeholder="请选择类型" style="display: inline-block;width:48%;margin-right:2%;">
            <Option v-for="item in companyList" :key="item.key" :value="item.key">{{item.value}}</Option>
          </Select>
          <Input v-model="form.industry" style="display:inline-block;width:48%;" placeholder="请输入行业名称（非必填）"></Input>
        </FormItem>
        <FormItem label="公司名称" prop="company">
          <Input v-model="form.company" placeholder="请输入公司名称"></Input>
        </FormItem>
        <FormItem label="注册时间">
          {{form.createTime}}
        </FormItem>
      </Form>
    </div>
    <span slot="footer">
      <Button ghost type="primary" @click="cancel()">取消</Button>
      <Button type="primary" style="margin-left:20px;" @click="ok()">确定</Button>
    </span>
  </Modal>
</template>

<script>
import { getCompanyBusinessScope } from '@/api/packing'
import { updateUserInfo } from '@/api/user'
export default {
  components: {
  },
  data () {
    return {
      visible: false,
      form: {
        code: ''
      },
      rules: {
        // nickName: [{ required: true, message: '账号昵称', trigger: 'blur' }]，
        businessScope: [{ required: true, message: '请选择行业类型' }],
        company: [{ required: true, message: '请输入公司名称' }]
      },
      companyList: []
    }
  },
  computed: {

  },
  methods: {
    show (data) {
      this.getCompanyBusinessScope()
      this.form = Object.assign({}, this.form, data)
      this.visible = true
    },
    ok () {
      console.log(this.form)
      this.$refs.Form.validate(valid => {
        if (valid) {
          updateUserInfo(this.form).then(res => {
            if (res.isSuccess) {
              this.$Message.success('修改成功')
              this.visible = false
              this.$store.dispatch('GET_USERINFO')
              this.$emit('cancel')
            } else {
              this.$Message.error(res.msg)
            }
          })
        }
      })
    },
    cancel () {
      this.visible = false
      this.$emit('cancel')
    },
    getCompanyBusinessScope () {
      getCompanyBusinessScope().then(res => {
        if (res.isSuccess) {
          this.companyList = res.data
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
