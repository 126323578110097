<template>
  <div class="count-down-button">
    <Button type="primary" ghost style="width:130px;" :disabled="status" @click="getCode">{{text}}</Button>
  </div>
</template>

<script>
import { sendSms } from '@/api/user'
export default {
  props: {
    tel: {
      type: String,
      defalut: ''
    },
    templateType: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      timer: null,
      second: 60,
      status: false
    }
  },
  computed: {
    text () {
      if (this.status) {
        return `${this.second}s后可再次获取`
      } else {
        return '点击发送验证码'
      }
    }
  },
  methods: {
    startTimer () {
      this.second = 60
      this.status = true
      this.timer = window.setInterval(() => {
        if (this.second >= 1) {
          this.second--
        } else {
          this.closeTimer()
        }
      }, 1000)
    },
    closeTimer () {
      this.status = false
      window.clearInterval(this.timer)
    },
    getCode () {
      if (this.tel && /^1\d{10}$/.test(this.tel)) {
        sendSms({ phonenumber: this.tel, templateType: this.templateType }).then(res => {
          if (res.isSuccess) {
            this.$Message.success('发送成功')
            this.$emit('input', res.data.recordId)
            this.startTimer()
            this.status = true
          } else {
            this.$Message.error(res.msg)
          }
        })
      } else {
        this.$Message.error('请输入正确的手机号码')
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.count-down-button {
    display: inline-block;
}
</style>
